import { useGLTF } from "@react-three/drei";

export function ChristmasTree(props: any) {
  const { nodes, materials }: any = useGLTF("/models/christmas-tree.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.stand_decorations_0.geometry}
        material={materials.decorations}
        position={[-0.001, 0.089, -0.001]}
        rotation={[-Math.PI / 2, 0, 0]}
        castShadow
      />
      <mesh
        geometry={nodes.gift_gifts_0.geometry}
        material={materials.gifts}
        position={[-0.209, 0.064, -0.095]}
        rotation={[-Math.PI / 2, 0, -0.544]}
        castShadow
      />
      <mesh
        geometry={nodes.Circle002__0.geometry}
        material={materials["Circle.002__0"]}
        position={[0, -0.399, -0.007]}
        rotation={[-Math.PI / 2, 0, -1.719]}
      />
      <mesh
        geometry={nodes["Plane002_ig��y_0"].geometry}
        material={materials.material}
        position={[-0.003, -0.477, 0.002]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[0.253, 0.253, 0.272]}
      />
      <mesh
        geometry={nodes["Plane002_pie��002_0"].geometry}
        material={materials["pie.002"]}
        position={[-0.003, -0.477, 0.002]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[0.253, 0.253, 0.272]}
      />
      <instancedMesh
        args={[nodes.bulb_decorations_0.geometry, materials.decorations, 6]}
        instanceMatrix={nodes.bulb_decorations_0.instanceMatrix}
      />
      <instancedMesh
        args={[nodes.bulb004_decorations_0.geometry, materials.decorations, 8]}
        instanceMatrix={nodes.bulb004_decorations_0.instanceMatrix}
      />
      <instancedMesh
        args={[
          nodes["Circle002|Circle004|Dupli|_decorations_0"].geometry,
          materials.decorations,
          153,
        ]}
        instanceMatrix={
          nodes["Circle002|Circle004|Dupli|_decorations_0"].instanceMatrix
        }
      />
      <instancedMesh
        args={[
          nodes["Circle002|Circle003|Dupli|5_decorations_0"].geometry,
          materials.decorations,
          152,
        ]}
        instanceMatrix={
          nodes["Circle002|Circle003|Dupli|5_decorations_0"].instanceMatrix
        }
      />
      <instancedMesh
        args={[
          nodes["Circle002|Circle001|Dupli|6_decorations_0"].geometry,
          materials.decorations,
          152,
        ]}
        instanceMatrix={
          nodes["Circle002|Circle001|Dupli|6_decorations_0"].instanceMatrix
        }
      />
      <instancedMesh
        args={[
          nodes["Circle002|Circle|Dupli|7_decorations_0"].geometry,
          materials.decorations,
          150,
        ]}
        instanceMatrix={
          nodes["Circle002|Circle|Dupli|7_decorations_0"].instanceMatrix
        }
      />
    </group>
  );
}

useGLTF.preload("/models/christmas-tree.glb");
